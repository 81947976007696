import React, {Fragment, useMemo} from 'react';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash.isempty';
import styled from 'styled-components';
import { Page, Loading, Flex, Typo } from '../../../../ui';
import { selectPage, selectPageStatus } from '../../coursePageSlice';
import { STATUS_FAILED, STATUS_SUCCEEDED, coursePageNavigation } from '../../../../utils/constants';
import { selectCourseTheme } from '../../../course/courseSlice';
import ModuleSwitcher from './ModuleSwitcher';
import Quiz from '../Quiz';
import { CONTENT_SMALL, CONTENT_STANDARD, media } from '../../../../utils/media';
import { getUserThemeColor } from '../../../../ui/theme/colors';
import { Breadcrumbs } from '@mui/material';
import NoAccessPage from './NoAccessPage';
import { selectCourseSectionById } from '../../viewCourseSlice';

const RowModules = styled(Flex)` 
  ${media.lessThan('laptop')} {
    flex-direction:${({$isNotesOpen})=> $isNotesOpen && 'column'};
  }
  ${media.lessThan('bigTablet')} {
    flex-direction:column;
  }

  //hide empty components so we dont have extra empty space
  &:empty{
    display:none;
  }
`
const ModuleGridContainer = styled.div`
  display: inline-grid;
  grid-template-columns: ${({$columns})=> $columns === 3 ? '32% 32% 32%' : '49% 49%'};
  overflow: auto;
  width: 100%;
  justify-content: space-between;

  ${media.lessThan('bigTablet')} {
    grid-template-columns: repeat(1, 98%);
    grid-gap: 25px;
  }

`

const CoursePage = ({pageId, isQuiz, quizData, isNotesOpen, biggerPageSize, pageInfo}) => {
  const page = useSelector(selectPage);
  const pageStatus = useSelector(selectPageStatus);
  const courseTheme = useSelector(selectCourseTheme);
  const activeSection = useSelector((state)=>selectCourseSectionById(state, pageInfo?.sectionId));

  const themeColor = useMemo(()=> getUserThemeColor(courseTheme?.color), [courseTheme]);

  const renderPageContent = () => {
    return (
      <>
        {page?.pageRows?.map(pageRow => {
          const pageModulesLength = pageRow.pageModules.length
          const ModuleContainer = pageModulesLength < 2 ? Fragment : ModuleGridContainer
          const props = pageModulesLength > 1 ? {$columns: pageModulesLength} : { }
                    
          return <RowModules $isNotesOpen={isNotesOpen} gap="20" width="100%" key={`pageRow-${pageRow.id}`} justify={pageModulesLength < 2 ? 'center' : "space-between"} align="flex-start">
            <ModuleContainer {...props} >
              {pageRow.pageModules.map(module => 
                <ModuleSwitcher $columns={pageModulesLength} key={`pageModules-${module.id}`} module={module} themeColor={themeColor} />)
              }
            </ModuleContainer>
          </RowModules>
          }
        )}
      </>
    );
  };

  const renderPageDiploma = () => {
    return (
      <>
        <RowModules $isNotesOpen={isNotesOpen} gap="20" width="100%" justify="center" align="normal">
          <ModuleSwitcher columns={1} key={'diploma'} module={{data:{}, type:{name:'diploma'}}} themeColor={themeColor} />
        </RowModules>
      </>
    );
  };

  const breadcrumbs = useMemo(() => {
    return [
      <Typo.MediumText key={`section-${activeSection?.name}`}>{activeSection?.name}</Typo.MediumText>,
      <Typo.MediumText key={`page-${page?.name}`}>{page?.name}</Typo.MediumText>,
      // <Typo.MediumText> currentPage / totalPages</Typo.MediumText>,
    ];
  }, [activeSection, page]);

  return (
    <Page 
      align='flex-start' 
      substractBottom={coursePageNavigation} 
      gap="60" 
      size={biggerPageSize ? CONTENT_STANDARD : CONTENT_SMALL}
    >
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        {breadcrumbs}
      </Breadcrumbs>
      {pageId === 'diploma' ? renderPageDiploma() : pageStatus === STATUS_FAILED ? <NoAccessPage /> : pageStatus === STATUS_SUCCEEDED && !isEmpty(page) ? (
        isQuiz ? <Quiz quizData={quizData} pageInfo={page} themeColor={themeColor} pageRows={page?.pageRows} />
          : renderPageContent()
      ) : <Loading themeColor={themeColor}/>}
    </Page>
  )
};

export default CoursePage;
