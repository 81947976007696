import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components'
import debounce from 'lodash.debounce';

import { SubPage, ListTable, SearchBar, Button, Flex, Icon, theme, IconAsButton } from '../../../ui';
import { selectCurrentStoreHideVat, selectStoreName } from '../globalSlice';
import { selectCourses } from '../../webshop/coursesSlice';
import { getPeriodByTime } from '../../../utils/ucpPeriods';
import { priceFormater } from '../../../helpers/formatNumbers';
import { selectIsIncarnated } from '../../auth/authSlice';
import { deleteCoursePackage, toggleCourseVisibility } from '../courseSlice';
import ConfirmDelete from '../../../ui/Modal/ConfirmDelete';
import EditShopCourseSettings from './EditShopCourseSettings';
import { selectFeatures } from '../../settings/settingsSlice';
import { Tooltip } from '@mui/material';

const SearchWrapper = styled.div`
  max-width: 450px;
  width: 100%;
  
`;
const Controls = styled(Flex)` 
  width: 100%;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 2rem;
`

const ShopCoursesList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch()
  const navigate = useNavigate();

  const [localCourses, setLocalCourses] = useState(null);
  const isIncarnated = useSelector(selectIsIncarnated);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [courseInfo, setCourseInfo] = useState(null);
  const [courseToDelete, setCourseToDelete] = useState(null);

  const storeName = useSelector(selectStoreName)
  const courses = useSelector(selectCourses);
  const searchRef = useRef();

  const storeHideVat = useSelector(selectCurrentStoreHideVat);
  const features = useSelector(selectFeatures);

  useEffect(() => {
    if (courses?.length) setLocalCourses(courses)
  }, [courses, isIncarnated])

  const navigateToCourse = (item) => {
    if (item.type === 1) navigate(`/store/${storeName}/${item.slug}/edit`);
    else navigate(`/store/${storeName}/package/${item.slug}/edit`);
  }

  const openModal = () => setIsModalOpen(true)
  const closeModal = () => {
    setCourseInfo(null)
    setIsModalOpen(false)
  }

  const toggleCoursePublic = useCallback((e, course) => {
    e.preventDefault()
    e.stopPropagation()
    dispatch(toggleCourseVisibility({ courseId: course.id, isPackage: course.type === 2 })).then((res) => {
      const modifiedCourses = [];
      localCourses.forEach((el, i) => {
        modifiedCourses.push({ ...el });
        if (el.id === course.id)
          modifiedCourses[i].public = res.payload.public;
      })
      setLocalCourses(modifiedCourses);
    })
  }, [dispatch, localCourses])

  const handlePackageSettings = (e, item) => {
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }
    setCourseInfo(item)
    setIsModalOpen(true)
  }

  const deletePackage = (e, item) => {
    e.preventDefault()
    e.stopPropagation()
    setCourseToDelete(item)
  }

  const deletePackageConfirmed = () => {
    dispatch(deleteCoursePackage(courseToDelete.id)).then(() => setCourseToDelete(null))
  }

  //it jumps after course being updated
  const tableInfo = [
    {
      label: '', data: (item) => <>
        {features.newDashboard && item.type === 1 ? 
          item.draft ? 
            <Tooltip title={t('globals.draft')} placement={'bottom'}> 
              <div><Icon.InConstruction color={theme.colors.NEUTRAL_60} /></div>
            </Tooltip>
            : <Tooltip title={t('globals.ready')} placement={'bottom'}>  
                <div><Icon.Published size='small' color={theme.colors.NEUTRAL_60} /></div> 
              </Tooltip>
              : item.type !== 1 && <Tooltip title={t('webshop.isPackage')} placement={'bottom'}>
                  <div><Icon.Package color={theme.colors.NEUTRAL_60} /></div>
                </Tooltip>
        }
      </>
    },
    { label: t('globals.course'), data: (item) => item.name },
    { label: t('globals.price'), data: (item) => priceFormater(item.price, storeHideVat, item.currency) },
    { label: t('globals.accessPeriod'), data: (item) => t(`course.${getPeriodByTime(item.ucpPeriod)?.label}`) },
    {
      label: t('webshop.inStore'), data: (item) => !!item.public
        ? <Flex gap='10' onClick={(e) => toggleCoursePublic(e, item)}>
          <Icon.Storefront color={theme.colors.SUCCESS_GREEN} />
          <Flex justify='center' width='80%'>
            <Button $asText onClick={(e) => toggleCoursePublic(e, item)}>{t('globals.remove')}</Button>
          </Flex>
        </Flex>
        : <Flex gap='10' onClick={(e) => toggleCoursePublic(e, item)}>
          <Icon.VisibleOff color={theme.colors.NEUTRAL_60} />
          <Flex justify='center' width='80%'>
            <Button $asText onClick={(e) => toggleCoursePublic(e, item)}>{t('globals.show')}</Button>
          </Flex>
        </Flex>
    },
    {label: t('globals.edit'), data: (item) => <IconAsButton clickHandle={(e) => navigateToCourse(item)} iconName='Edit' /> },
    {label: t('globals.settings'), data: (item) => <IconAsButton clickHandle={(e) => handlePackageSettings(e, item)} iconName='Settings' /> },
    {label: t('globals.package'), data: (item) => item.type === 1 ? '-' : <IconAsButton clickHandle={(e) => deletePackage(e, item)} iconName='Delete' />},
  ].filter(Boolean);

  const handleSearch = useCallback(debounce((e) => {
    const value = searchRef.current.value.toLowerCase()
    setLocalCourses(courses.filter((el) => el.name.toLowerCase().includes(value)))

  }, 1000), [dispatch, searchRef]);

  const clearSearch = useCallback(() => {
    searchRef.current.value = '';
    courses?.length && setLocalCourses(courses);
  }, [courses, searchRef]);

  return (
    <SubPage staticElements={
      <Controls>
        <SearchWrapper>
          <SearchBar
            placeholder={t('webshop.course.searchCourse')}
            handleSearch={handleSearch}
            handleClear={clearSearch}
            disableClear={!searchRef.current?.value}
            inputRef={searchRef}
          />
        </SearchWrapper>
        <Button icon='Edit' onClick={openModal}>{t('webshop.createPackage')}</Button>
      </Controls>
    }>
      <ListTable
        alignLeft={1}
        tableInfo={tableInfo}
        data={localCourses}
        tableName={'courses'}
      />
      
      <EditShopCourseSettings
        open={isModalOpen}
        courseId={courseInfo?.id}
        tempCourse={courseInfo}
        isPackage={courseInfo?.type === 1 ? false : true}
        onClose={closeModal}
      /> 
      
      <ConfirmDelete
        onClose={setCourseToDelete}
        onAcceptDelete={deletePackageConfirmed}
        open={!!courseToDelete?.id}
        description={t('webshop.course.confirmDeleteCoursePackage', { courseToDelete: courseToDelete?.name })}
      />
    </SubPage >
  );
};

export default ShopCoursesList;
