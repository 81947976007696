const crossSliceMiddleware = (store) => (next) => (action) => {
  switch (action.type) {
    case 'dashboard/fetchDashboard/pending':
      store.dispatch({ type: 'course/clearCourse' });
      store.dispatch({ type: 'coursePage/clearPage' });
      store.dispatch({ type: 'viewCourse/reset' })
      break;

    // Add other cases if needed
    default:
      break;
  }

  // Always pass the action to the next middleware or reducer
  return next(action);
};

export default crossSliceMiddleware;
