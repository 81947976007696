import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Filter, Page, Typo, Flex } from '../../ui';
import {
  fetchNotifications,
  markNotification,
  selectNotificationFilter,
  selectNotificationsFiltered,
  selectStatus,
  selectUnreadNotifications,
  setNotificationFilter
} from './notificationsSlice';
import styled from 'styled-components';
import { selectIsIncarnated } from '../auth/authSlice';
import NotificationItem from './NotificationItem';
import { STATUS_LOADING } from '../../utils/constants';
import { useTranslation, Trans } from 'react-i18next';
import PageControls from '../../ui/Page/PageControls';
import PageHeader from '../../ui/Page/PageHeader';

const NotificationsList = styled.ul`
  margin: 0 0 2rem 0;
  width: 100%;
  padding: 0;
`;

const transformMessageLinks = (notifications, extraParams = {}) => {
  return notifications.map((item) => {
    const courseId = item.objects?.course?.[0];
    const pageId = item.objects?.page?.[0];
    const postId = item.objects?.post?.[0];
    const taskId = item.objects?.task?.[0];

    const isLocalhost = typeof window !== 'undefined' &&
      (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1');

    let transformedMessage = item.message;

    if (item.message.includes('/invitation/')) {
      if (isLocalhost) {
        transformedMessage = item.message.replace(
          /(https?:\/\/)[^/]+(\/invitation\/[^"']+)/g,
          (_, __, path) => `http://localhost:3000${path}`
        );
      }
    }
    else if (taskId) {
      if (isLocalhost) {
        transformedMessage = item.message.replace(
          /(https?:\/\/[^/]+\/courses\/[^"']+)/g,
          () => `http://localhost:3000/courses/${courseId}/tasks/${taskId}`
        );
      }
    }
    else if (courseId && pageId) {
      const anchor = postId ? `#post-${postId}` : '';
      const newPath = `/view/course/${courseId}/${pageId}${anchor}`;
      const queryParams = new URLSearchParams(extraParams).toString();
      const finalPath = queryParams ? `${newPath}?${queryParams}` : newPath;

      transformedMessage = item.message.replace(
        /(https?:\/\/[^/]+)\/course(s?)\/[^"']+/g,
        (_, baseUrl) => isLocalhost ? `http://localhost:3000${finalPath}` : `${baseUrl}${finalPath}`
      );
    }
    else if (item.message.includes('added you to') && courseId) {
      const newPath = `/view/course/${courseId}`;
      transformedMessage = item.message.replace(
        /(https?:\/\/[^/]+)\/course(s?)\/[^"']+/g,
        (_, baseUrl) => isLocalhost ? `http://localhost:3000${newPath}` : `${baseUrl}${newPath}`
      );
    }

    return {
      ...item,
      message: transformedMessage,
    };
  });
};

const Notifications = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const notifications = useSelector(selectNotificationsFiltered);
  const filters = useSelector(selectNotificationFilter);
  const isIncarnated = useSelector(selectIsIncarnated);
  const unreadNotifications = useSelector(selectUnreadNotifications);
  const notificationsStatus = useSelector(selectStatus);

  const [length, setLength] = useState(25);
  const [notificationType, setNotificationType] = useState('0');
  const [searchQuery, setSearchQuery] = useState('');

  const handleSelected = useCallback(item => {
    dispatch(markNotification({ method: item.isRead === 0 ? 'markRead' : 'markUnread', ids: [item.id] })).then(() =>
      dispatch(fetchNotifications({ types: notificationType, reversed: false, pageLength: length }))
    )
  }, [dispatch, length, notificationType]);

  const archiveNotifications = useCallback((method) => {
    dispatch(markNotification({ method, markAll: true })).then(() =>
      dispatch(fetchNotifications({ types: notificationType, reversed: false, pageLength: length }))
    )
  }, [notificationType, length, dispatch])

  //Filter for notifications
  const handleNotificationType = useCallback((value) => {
    setNotificationType(value)
    dispatch(setNotificationFilter(value))
  }, [dispatch]);

  const loadMore = useCallback(() => {
    setLength((prevLength) => prevLength + 25);
  }, []);

  const handleSearch = useCallback((value) => {
    setSearchQuery(value);
  }, []);

  const clearSearch = useCallback(() => {
    setSearchQuery('');
  }, []);

  const transformedNotifications = useMemo(
    () => transformMessageLinks(notifications),
    [notifications]
  );

  const filteredItems = useMemo(() => {
    return transformedNotifications?.filter((item) =>
      item.message.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }, [transformedNotifications, searchQuery]);

  useEffect(() => {
    dispatch(fetchNotifications({ types: notificationType, reversed: false, pageLength: length }));
  }, [dispatch, length, isIncarnated, notificationType, unreadNotifications]);

  return (
    <Page staticElements={
      <PageHeader>
        <PageControls
          onClearSearch={clearSearch}
          onSearch={handleSearch}
          placeholder={'Search'}
          disableClear={!searchQuery}
          openModelButton={
            notificationType === '0' ? <Button icon='Archive' onClick={() => archiveNotifications('markRead')}>{t('notifications.archiveAll')}</Button> :
              notificationType === '4' && <Button icon='Archive' onClick={() => archiveNotifications('markUnread')}>{t('notifications.unarchiveAll')}</Button>
          }
        >
          <Filter info={filters} handleFilter={handleNotificationType} selected={notificationType} />
        </PageControls>
      </PageHeader>
    }>
      <Flex width='100%' direction='column' align='flex-start' gap='20'>
        <Flex direction='column' align='flex-start' gap='5'>
          <Typo.Info>{t('notifications.archiveForNoEmail')}</Typo.Info>
          <Typo.Info>
            <Trans t={t} i18nKey={'notifications.switch'} components={[<a href='/profile/notifications'>{t('globals.notifications')}</a>]} />
          </Typo.Info>
        </Flex>
        {filteredItems?.length ? (
          <NotificationsList>
            {filteredItems.map((item) => (
              <NotificationItem key={item.id} info={item} selectClick={() => handleSelected(item)} />
            ))}
          </NotificationsList>
        ) : (
          notificationsStatus !== STATUS_LOADING && <Typo.ContentSmallHeader>{t('notifications.noMatch')}</Typo.ContentSmallHeader>
        )}
        {filteredItems?.length >= 25 && <Button onClick={loadMore}>{t('notifications.loadMore')}</Button>}
      </Flex>
    </Page>
  );
};

export default Notifications;
