import SteelCompass from '../services/SteelCompass';
import { enqueueSnackbar } from '../../../../modern/node_modules/notistack';

class Page {
  createPageModule(type, pageId, callback) {
    SteelCompass.call(
      'page-module',
      'create',
      { type, newRow: true, pageId },
      response => {
        if (callback) {
          callback(response);
        }
      }
    );
  }

  savePageModule(id, params, callback) {
    SteelCompass.call('page-module', 'update', { id, params }, response => {
      enqueueSnackbar('Your changes have been saved.', {variant: 'success'})
      if (callback) {
        callback(response);
      }
    });
  }

  savePageModuleExtended(data, callback) {
    SteelCompass.call('page-module', 'update', data, response => {
      enqueueSnackbar('Your changes have been saved.', {variant: 'success'})
      if (callback) {
        callback(response);
      }
    });
  }

  deletePageModule(id, callback) {
    SteelCompass.call('page-module', 'delete', { id }, response => {
      if (callback) {
        callback(response);
      }
    });
  }

  deletePageRow(id, callback) {
    SteelCompass.call('page-row', 'delete', { id }, response => {
      if (callback) {
        callback(response);
      }
    });
  }

  moveToSection(pageId, sectionId, position, callback) {
    SteelCompass.call(
      'page',
      'moveToSection',
      { pageId, sectionId, position },
      response => {
        if (callback) {
          callback(response);
        }
      }
    );
  }
}

export default new Page();
