import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Flex, theme, IconAsButton } from '../../ui';
import { fetchCourseById, getCourseProgress, selectCourse } from '../course/courseSlice';
import { useNewViewCourseId, useNewViewPageId } from '../../hooks/readFromUrls';
import { drawerWidth } from '../../utils/constants';
import { media } from '../../utils/media';
import TocMenu from './components/TocMenu';
import CoursePage from './components/CoursePage';
import { getDarkerThemeColor, getLighterThemeColor, getUserThemeColor } from '../../ui/theme/colors';
import NotesDrawer from './components/NotesDrawer/Index';
import { selectNotesSliderToggle, setNotesSliderToggle } from '../global/globalSlice';
import NotesMobile from './components/NotesDrawer/NotesMobile';
import usePageNavigation from '../../utils/pageNavigation';
import useSwipe from './components/useSwipes';
import {
  selectCoursePageById,
  selectCourseSectionById,
  selectCurrentPageId,
  selectNextAvailablePageId,
  selectTocMenuIsClosed,
  setActiveSectionId,
  setCurrentPageId,
  toggleTocMenuIsClosed } from './viewCourseSlice';
import NavButtons from './components/NavButtons'
import { fetchPageById, markPageAsViewed } from './coursePageSlice';

const LockedSectionMessage = styled.div`
  padding: 20px;
  text-align: center;
  color: ${theme.colors.NEUTRAL_60};
`;

const ContentWrapper = styled(Flex)`
  margin-left: ${({ $isTocMenuClosed }) => ($isTocMenuClosed ? '46' : drawerWidth)}px;
  position: relative;
  height: calc(100vh - ${theme.headerSize});
  margin-right: 0;

  ${media.greaterThan('bigTablet')} {
    margin-right: ${({ $extraMargin }) => ($extraMargin ? drawerWidth : 0)}px;
  }

  ${media.lessThan('tablet')} {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }
`;

const ButtonWrapper = styled.div`
  position: absolute;
  top: calc(${theme.headerSize} + 10px);
  left: ${({$minimizedMenu})=> $minimizedMenu ? 0 : `calc( ${drawerWidth}px - 46px)`};
  z-index: 3;

  ${media.lessThan('tablet')} {
    display:none;
  }
`

const ViewCourse = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const urlPageId = useNewViewPageId();
  const courseId = useNewViewCourseId();
  const currentPageId = useSelector(selectCurrentPageId);
  const course = useSelector(selectCourse);
  const isNotesOpen = useSelector(selectNotesSliderToggle);
  const isTocMenuClosed = useSelector(selectTocMenuIsClosed);
  const nextAvailablePageId = useSelector(selectNextAvailablePageId);

  const [hasMarkedViewed, setHasMarkedViewed] = useState(false);

  const page = useSelector((state) => selectCoursePageById(state, currentPageId));
  const section = useSelector((state)=> selectCourseSectionById(state, page?.sectionId));

  useEffect(() => {
    courseId && dispatch(fetchCourseById({ courseId }));
  }, [courseId, dispatch]);

  useEffect(() => {
    if(urlPageId && urlPageId !== currentPageId){
      dispatch(setCurrentPageId(urlPageId));
    }
  }, [dispatch, urlPageId, currentPageId]);


  useEffect(()=>{
    page?.sectionId && dispatch(setActiveSectionId(page.sectionId))
  }, [page, dispatch]);

  useEffect(() => {
    if (!!currentPageId && currentPageId !== 'diploma'){
      dispatch(fetchPageById({pageId:currentPageId}));
    }
  }, [currentPageId, dispatch, courseId]);

  useEffect(() => {
    const markAndUpdate = async () => {
      if (!!page && !page.isLocked && currentPageId && !hasMarkedViewed) {
        await dispatch(markPageAsViewed({ pageId: currentPageId, courseId }));
        dispatch(getCourseProgress({ courseId }));
        setHasMarkedViewed(true);
      }
    };

    markAndUpdate();
  }, [page, courseId, currentPageId, dispatch, hasMarkedViewed]);

  // Reset when pageId changes
    useEffect(() => {
      setHasMarkedViewed(false);
    }, [currentPageId]);


  // useEffect(() => {
  //   if(!!page && !page.isLocked && currentPageId){
  //     dispatch(markPageAsViewed({pageId:currentPageId, courseId}));
  //   }
  // } ,[page, courseId, currentPageId, dispatch]);

  useEffect(() => {
    if((section?.isLocked || page?.isLocked || currentPageId === 0) && nextAvailablePageId)
      navigate(`/view/course/${courseId}/${nextAvailablePageId}`, { replace: true });
}, [page, section, nextAvailablePageId, courseId, navigate, currentPageId]);

  const { prev, next } = usePageNavigation(course?.toc || [], currentPageId);

  const isPageLocked = useCallback((pageId) => {
    return course?.toc?.some((section) => section.isLocked && section.pages.some((page) => page.id === pageId));
  }, [course.toc]);

  const goToPage = useCallback((pageId) => {
    if (pageId === 'diploma' || !isPageLocked(pageId)) {
      navigate(`/view/course/${courseId}/${pageId}`, { replace: true });
    }
  },[courseId, isPageLocked, navigate]);

  const handleKeyPress = useCallback((event) => {
    const { activeElement } = document;

    if (activeElement && (activeElement.tagName === 'INPUT' || activeElement.tagName === 'TEXTAREA')) {
      return;
    }

    if(event.key === 'ArrowRight' && next?.id) goToPage(next?.id)
    else if(event.key === 'ArrowLeft' && prev?.id) goToPage(prev?.id)
  }, [next, prev, goToPage])

  useEffect(()=>{
    document.addEventListener('keydown', handleKeyPress);
    return () => document.removeEventListener('keydown', handleKeyPress);
  }, [handleKeyPress])

  const handleOpenNotes = () => {
    dispatch(setNotesSliderToggle(!isNotesOpen));
  };

  const themeColor = useMemo(() => getUserThemeColor(course?.theme?.color), [course?.theme?.color]);// eslint-disable-line react-hooks/exhaustive-deps
  const lightColor = useMemo(() => getLighterThemeColor(themeColor), [themeColor]);
  const darkColor = useMemo(() => getDarkerThemeColor(themeColor), [themeColor]);

  const allSectionsLocked = useMemo(() => {
    const allIsLocked = course?.toc?.filter((section)=>section.id !== 'Diploma').every((section) => section.isLocked);
    return allIsLocked;
  }, [course]);

  const handleSwipeRight = useCallback(() => {
    if(prev?.id)
      goToPage(prev?.id)
  }, [prev, goToPage]);

  const handleSwipeLeft = useCallback(() => {
    if(next?.id)
      goToPage(next?.id);
  }, [next, goToPage]);

  const isQuiz = useMemo(() => {
    // we have some bugs in place when duplicating courses that makes quiz pages to disapear. get this back when we fix this.
    // const hasQuiz = course?.settings?.quiz?.enabled && course?.settings?.quiz?.pages?.hasOwnProperty(urlPageId);
    const hasQuiz = course?.settings?.quiz?.enabled;
    return hasQuiz && section?.type === 'quiz';
  }, [course, section]);

  useSwipe(handleSwipeLeft, handleSwipeRight);

  const toggleMenuOpen = () => {
    dispatch(toggleTocMenuIsClosed())
  }

  return (
    <>
      <ButtonWrapper $minimizedMenu={isTocMenuClosed}>
        <IconAsButton
          clickHandle={toggleMenuOpen}
          tooltipLabel={!!isTocMenuClosed ? t('coursePage.expand') : t('coursePage.minimize')}
          iconName={!!isTocMenuClosed ? 'List' : 'DoubleArrowLeft'}
        />
      </ButtonWrapper>
      <TocMenu isTocMenuClosed={isTocMenuClosed} />
      <ContentWrapper direction='column' $extraMargin={isNotesOpen} $isTocMenuClosed={isTocMenuClosed} >
        {currentPageId !== 'diploma' && allSectionsLocked ? (
          <LockedSectionMessage>
            <h2>{t('coursePage.allLocked')}</h2>
            <p>{t('coursePage.contactAdmin')}</p>
          </LockedSectionMessage>
        ) : (
          <>
            <CoursePage
              biggerPageSize={isTocMenuClosed}
              courseId={courseId}
              pageId={currentPageId}
              isQuiz={isQuiz}
              pageInfo={page}
              isNotesOpen={isNotesOpen}
              quizData={isQuiz ? course.settings.quiz.pages[currentPageId] : null}
            />
            <NotesMobile fullWidth={isTocMenuClosed} makeItOpen={isNotesOpen} setNotesDrawerOpen={handleOpenNotes} />
            <NavButtons
              prev={prev}
              next={next}
              goToPage={goToPage}
              isTocMenuClosed={isTocMenuClosed}
              isNotesOpen={isNotesOpen}
              lightColor={lightColor}
              darkColor={darkColor}
              handleOpenNotes={handleOpenNotes}
              themeColor={themeColor}
            />
          </>
        )}
      </ContentWrapper>
      <NotesDrawer makeItOpen={isNotesOpen} setNotesDrawerOpen={handleOpenNotes} />
    </>
  );
};

export default ViewCourse;
