import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { STATUS_FAILED, STATUS_LOADING, STATUS_SUCCEEDED, TOAST_ERROR, TOAST_SUCCESS } from '../../utils/constants';
import { mrWolfUserApi, settingsApi, storeApi } from '../../utils/urls';
import { enqueueSnackbar } from 'notistack'
import httpClient from "../../services/httpClient";
import i18next from "i18next";

// ----------------- Thunks -----------------------------

export const fetchSettings = createAsyncThunk('settings/fetchSettings', async (_, { getState, dispatch, rejectWithValue }) => {
  const body = {
    data: {},
    method: 'read'
  };

  const res = await httpClient.post(settingsApi(), body, { getState, dispatch, rejectWithValue });

  return res.data;
});

export const updateSettings = createAsyncThunk('settings/updateSettings', async ({ settingsData }, { getState, dispatch, rejectWithValue }) => {
  const body = {
    data: settingsData,
    method: 'update'
  };

  const res = await httpClient.post(settingsApi(), body, { getState, dispatch, rejectWithValue });
  return res.isError ? res.data : settingsData; // BE SHOULD RETURN UPDATED OBJECT
});

export const updateCurrency = createAsyncThunk('global/updateCurrency', async (currency, { getState, dispatch, rejectWithValue }) => {
  const body = {
    data: {
      currency,
    },
    method: 'changeCurrency'
  };

  const res = await httpClient.post(storeApi(), body, { getState, dispatch, rejectWithValue });
  return res.data;
})

export const fetchStoreCurrency = createAsyncThunk('global/fetchStoreCurrency', async (props, { getState, dispatch, rejectWithValue }) => {
  const body = {
    data: {
      storeId: props?.storeId
    },
    method: 'getCurrency'
  };

  const res = await httpClient.post(storeApi(), body, { getState, dispatch, rejectWithValue });

  return res.data;
})

export const updateExVat = createAsyncThunk('global/updateExVat', async ({ exVat }, { getState, dispatch, rejectWithValue }) => {
  const body = {
    data: {
      exVat
    },
    method: 'setBusinessExtension'
  };

  const res = await httpClient.post(mrWolfUserApi(), body, {getState, dispatch, rejectWithValue });
  return res.data;
})

// ----------------- Reducers -----------------------------

const initialState = {
  noCourseExpire: false,
  hideVAT: false,
  fetchStatus:'idle',
  features: {
    // enterpriseStore: false,
    // klarnaCheckout: false,
    showTaskResultOnAnswer: false,
    diplomas: false,
    dripContent: false,
    quiz: false,
    newCourseView: true, // to be removed

    // New feature flags
    packageSequence: false,
    diplomaDownload: true, // to be removed
    newTaskView: true, // to be removed
    addTeacherToCourse: false,
    newDashboard:false,
    courseCategories:false,
    salesExport: false,
    // draftCourses: false,
  }
}

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setFeatures(state, action) {
      state.features = action.payload;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchSettings.pending, (state) => {
        state.fetchStatus = STATUS_LOADING
      })
      .addCase(fetchSettings.rejected, (state) => {
        state.fetchStatus = STATUS_FAILED
      })
      .addCase(fetchSettings.fulfilled, (state, action) => {
        const { features } = action.payload;
        const newfeatures = { ...initialState.features };

        features && Object.keys(features).forEach(key => {
          newfeatures[key] = features[key]
        });

        return {
          ...action.payload,
          // hideVAT: initialState.hideVAT,
          fetchStatus: STATUS_SUCCEEDED,
          features: newfeatures
        }
      })
      .addCase(updateSettings.pending, (state) => {
        state.status = STATUS_LOADING;
      })
      .addCase(updateCurrency.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        enqueueSnackbar(action.payload?.response?.errMsg, { variant: TOAST_ERROR })
      })
      .addCase(updateCurrency.fulfilled, (state, action) => {
        enqueueSnackbar(i18next.t('settings.currencyUpdated'), { variant: TOAST_SUCCESS })
      })
      .addCase(updateSettings.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        enqueueSnackbar(action.payload?.response?.errMsg, { variant: TOAST_ERROR })
      })
      .addCase(updateSettings.fulfilled, (state, action) => {
        enqueueSnackbar(i18next.t('settings.settingsUpdated'), { variant: TOAST_SUCCESS })
        return { ...state, ...action.payload, status: STATUS_SUCCEEDED };
      })
      .addCase(updateExVat.fulfilled, (state, action) => {
        enqueueSnackbar(action.payload.message, { variant: TOAST_SUCCESS })
      })
      .addCase(updateExVat.rejected, (state, action) => {
        enqueueSnackbar(i18next.t('settings.updateFailed'), { variant: TOAST_ERROR })
      })
  }
})

export const { setFeatures } = settingsSlice.actions

export default settingsSlice.reducer;

// ----------------- Selectors -----------------------------

export const selectNoCourseExpire = state => state.settings.noCourseExpire;
export const selectSettings = state => state.settings;
export const selectCurrency = state => state.settings.currency;
export const selectStoreHideVAT = state => state.settings.hideVAT;
export const selectFeatures = state => state.settings.features;
export const selectFetchStatus = state => state.settings.fetchStatus;
export const selectZroom = state => state.settings.zroom;

export const ADMIN_ONLY_FEATURES = [
  'enterpriseStore',
  'klarnaCheckout',
  'showTaskResultOnAnswer',
  'addTeacherToCourse',
  'salesExport',
  'packageSequence',
  'courseCategories',
  'newDashboard',
]

export const FEATURE_FLAGS = [
  'newDashboard',
  'salesExport',
  'addTeacherToCourse',
  'packageSequence',
  'courseCategories',
]

export const DELETED_FLAGS = [
  'newFixedMenu',
  'deleteMediaFolders',
  'diplomaDownload',
  'newCourseView',
  'draftCourses',
  'newTaskView',
]
