import React, { useRef, useCallback } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Typo} from '../../../../ui';
import { useDispatch } from 'react-redux';
import { TASK_TYPE } from '../../../../utils/constants';
import { List } from '@mui/material';
import { answerOnTask } from '../../../tasks/tasksSlice';
import { updateTaskResult } from '../../coursePageSlice';
import Results from './Results';
import TextType from './TextType';
import SingleChoiceType from './SingleChoiceType';
import MultipleChoiceType from './MultipleChoiceType';
import { getLighterThemeColor } from '../../../../ui/theme/colors';
import TaskHeaderMedia from './TaskHeaderMedia';

const ResultsContainer = styled.div`
  padding:20px;
  & ul {
    padding: 0;
    margin-top:0;
  }
`

const TaskContainer = styled.div`
  background-color:${({$bgColor}) => $bgColor};   
  padding:20px;
  border-radius: 11px;
`

const Wrapper = styled.div`
  align-self: start;
  flex-basis:${props => props.$split50 && '50%'};
  width: 100%;
`

const TaskModule = ({
  data, module, isQuiz = false, themeColor,
  sessionResults = [], setSessionResults = null,
  setTaskTracking, currentTaskIndex, setCurrentTaskIndex,
  totalTasks,
  split50
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const textTaskResultRef = useRef({});
  const taskContent = data.params;

  const { task, taskResults } = taskContent;
  const { main, items } = task.caption
  const { type } = task;

  const setLocalResult = useCallback((value) => {
    setTaskTracking(prevState => ({ ...prevState, [task.taskId]: true }));
    const existingTaskIndex = sessionResults.findIndex(result => result.taskId === task.taskId);

    if (existingTaskIndex !== -1) {
      const updatedResults = [...sessionResults];

      updatedResults[existingTaskIndex] = { taskId: task.taskId, value };
      setSessionResults(updatedResults);
    } else {
      setSessionResults([...sessionResults, { taskId: task.taskId, value }]);
    }
    if (currentTaskIndex < (totalTasks - 1)) {
      setCurrentTaskIndex(currentTaskIndex + 1)
    }
  }, [sessionResults, setSessionResults, task.taskId, setTaskTracking, totalTasks, currentTaskIndex, setCurrentTaskIndex])

  const answerTask = useCallback((value) => {
    isQuiz && setLocalResult(value);
    dispatch(answerOnTask({ pmid: module.id, taskId: task.taskId, value, isQuiz })).then(res => {
      dispatch(updateTaskResult({...res.payload.taskResult, moduleId: module.id}));
    });
  }, [dispatch, module.id, task.taskId, isQuiz, setLocalResult]);
  
  // main caption can be empty - aparently some clients use a text module to explain the task instead of using a main caption
  if((!(type === TASK_TYPE.OPEN || type === TASK_TYPE.FILE) && !items?.length)) return null;

  const taskHeaderFile = task?.frontend?.length ? JSON.parse(task.frontend) : task.captionFiles && task.captionFiles[0];

  return (
    <Wrapper $split50={split50} >
      <TaskContainer $bgColor={getLighterThemeColor(themeColor)}>
        <Typo.ContentSmallHeader $textAlign='left' $marginBottom='10px'>{main}</Typo.ContentSmallHeader>
        {!!taskHeaderFile && <TaskHeaderMedia data={taskHeaderFile} />}
        {(type === TASK_TYPE.OPEN || type === TASK_TYPE.FILE) && <TextType data={task} taskRef={textTaskResultRef} onSubmit={answerTask} themeColor={themeColor}/>}
        {type === TASK_TYPE.SINGLE && <SingleChoiceType results={sessionResults} data={task} t={t} onSubmit={answerTask} themeColor={themeColor}/>}
        {type === TASK_TYPE.MULTIPLE && <MultipleChoiceType results={sessionResults} data={task} t={t} onSubmit={answerTask} themeColor={themeColor}/>}
      </TaskContainer>
      {!!taskResults.length && !isQuiz && <ResultsContainer>
        <List sx={{ width: '100%' }}>
          {taskResults.map(result => !!result?.id && <Results taskOptions={items} result={result} taskType={type} key={result?.id} themeColor={themeColor}/>)}
        </List>
      </ResultsContainer>}
    </Wrapper>
  );
};

export default TaskModule;
