import React, { useEffect, useRef, useState } from 'react';
import { Flex, IconAsButton, Textinput, Typo, theme } from '../../../../ui';
import styled from 'styled-components';
import { media } from '../../../../utils/media';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPageById, selectPage } from '../../coursePageSlice';
// import { useTranslation } from 'react-i18next';
import { createNote, deleteNote, saveNotes } from '../../../notes/notesSlice';
import { useTranslation } from 'react-i18next';


const NotesItem = styled(Flex)`
  padding:6px 4px 6px 12px;

  &:hover {
    background-color:${theme.colors.NEUTRAL_LIGHT};
  }
`

const Container = styled.div`
  padding: 1rem 0 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;
  border-radius: 15px;
  overflow: hidden;
  height: calc(100vh - ${theme.headerSize});

  ${media.lessThan('bigTablet')} {
    min-height: ${({ $hasNotes }) => ($hasNotes ? '250px' : '150px')};
    max-height: ${({ $hasNotes }) => ($hasNotes ? '80vh' : 'auto')};
    height: auto;
    overflow: hidden;
    padding: 0.5rem 0 0;
  }

  ${media.lessThan('phablet')} {
    max-height: ${({ $hasNotes }) => ($hasNotes ? '70vh' : 'auto')};
  }
`;

const TitleContainer = styled.div`
  flex-shrink: 0;
  padding: 6px 20px 6px;
  background: white;
  z-index: 20;

  ${media.lessThan('phablet')} {
    padding: 5px 20px 5px;
  }
`;

const NotesList = styled.ul`
  margin: 6px;
  padding: 0;
  flex-grow: 1;
  overflow-y: auto;

  ${media.lessThan('bigTablet')} {
    max-height: 40vh;
  }

  ${media.lessThan('phablet')} {
    max-height: 35vh;
  }
`;

const InputContainer = styled.div`
  padding: 1rem;
  text-align: right;
  background-color: white;
`;

const NotesContent = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [notesForPage, setNotesForPage] = useState([]);
  const page = useSelector(selectPage);
  const notesRef = useRef(null);

  useEffect(() => {
    if (page?.pageRows?.length) {
      let notes = [...page.notes];
      page.pageRows.forEach((row) => {
        row.pageModules.forEach((module) => {
          const note = {...module.note, type:'module', id:module.id}
          if (module.note.text) notes.push(note);
        });
      });
      setNotesForPage(notes);
    }
  }, [page]);

  const onSave = () => {
    if (notesRef.current?.value.trim()) {
      dispatch(createNote({ pageId:page.id, text: notesRef.current.value }))
      .then(() => {
        notesRef.current.value = '';
        dispatch(fetchPageById({pageId:page.id}))
      });
    }
  };

  const onDelete = (note) => {
    if(!!note.type && note.type ==='module') {

      dispatch(saveNotes({ pageModuleId:note.id, text: '' }))
      .then(() => dispatch(fetchPageById({pageId:page.id})));

    }
    else {
      dispatch(deleteNote({ pageId:page.id, noteId:note.id }))
      .then(() => dispatch(fetchPageById({pageId:page.id})));
    }
  };

  return (
    <Container $hasNotes={notesForPage.length > 0}>
      <TitleContainer>
        <Typo.ContentBigHeader>Your notes</Typo.ContentBigHeader>
      </TitleContainer>
      <NotesList $hasNotes={notesForPage.length > 0}>
        {notesForPage.map((note, index) => (
          <NotesItem key={index} align='baseline' gap='10'>
            <Typo.Text>{note.text}</Typo.Text>
            <IconAsButton iconName='Delete' size='x-small' clickHandle={()=> onDelete(note)} />
          </NotesItem>
        ))}
      </NotesList>
      <InputContainer>
        <Textinput
          key={'take-a-note'}
          inputRef={notesRef}
          label={t('coursePage.notes.writeNote')}
          isMultiline
          endIcon={
            <IconAsButton size={'x-small'} iconName={'Send'} clickHandle={onSave} />
          }
        />
      </InputContainer>
    </Container>
  );
};

export default NotesContent;
